/* eslint-disable max-lines */
import { AddUserIcon } from 'assets/icons/AddUserIcon';
import { ClientIcon } from 'assets/icons/ClientIcon';
import { ClientRequestNotificationIcon } from 'assets/icons/ClientRequestNotificationIcon/ClientRequestNotificationIcon';
import { InertWasteApplicationNotificationIcon } from 'assets/icons/ClientRequestNotificationIcon/InertWasteApplicationNotificationIcon';
import { HistoryIcon } from 'assets/icons/HistoryIcon';
import { UserIcon } from 'assets/icons/UserIcon';
import {
  BusinessAdminClientRequestsPage,
  BusinessAdminInertWasteApplicationsPage,
  ClientCompaniesPage,
  ClientRequestsPage,
  HistoryPage,
  InertWasteApplicationPage,
  SitesAndDocumentsPage,
  UsersPage,
} from 'pages/lazyLoadedPages';
import { ReferentsManagementPage } from 'pages/lazyLoadedPages';
import { UserApplication } from 'redux/User/selectors';
import { ROUTES } from 'routing/routes';
import {
  ANALYTICS_PROVIDER_ACTIONS,
  ANALYTICS_PROVIDER_CATEGORIES,
  ANALYTICS_PROVIDER_LABELS,
} from 'services/analytics/config';
import { PLATFORM_IS_DAP_ENABLED, PLATFORM_LEGAL_CORPORATION_LABEL } from 'services/api/constants';
import {
  BUSINESS_ADMIN_ROLE_CODE,
  CLIENT_ADMIN_ROLE_CODE,
  CLIENT_CONTRIBUTOR_ROLE_CODE,
  TECHNICAL_SUPER_ADMIN_ROLE_CODE,
} from 'services/api/roles/types';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

import { MapMarkerIcon } from '../../assets/icons/MapMarkerIcon';
import { MenuPageConfig } from './types';

export const useMenuPageConfigsWithoutHomePage = (): MenuPageConfig[] => {
  const lateralMenuIntl = useIntlByKey('menu');
  const homePageIntl = useIntlByKey('home');

  return [
    {
      path: ROUTES.CLIENT_REQUESTS,
      linkPath: ROUTES.CLIENT_REQUESTS,
      icon: ClientRequestNotificationIcon,
      applications: [UserApplication.FRONT_OFFICE],
      roles: [CLIENT_ADMIN_ROLE_CODE, CLIENT_CONTRIBUTOR_ROLE_CODE],
      analyticsProviderEventConfig: {
        action: ANALYTICS_PROVIDER_ACTIONS.CLIENT_REQUESTS_PAGE_VIEW,
        label: ANALYTICS_PROVIDER_LABELS.PAGE_VIEW,
        category: ANALYTICS_PROVIDER_CATEGORIES.CLIENT_REQUESTS,
      },
      pageComponent: ClientRequestsPage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('client-requests'),
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.client-requests.title'),
        description: homePageIntl('menu-sections.client-requests.description'),
        actionButtonLabel: homePageIntl('menu-sections.client-requests.action-button-label'),
      },
    },
    ...((PLATFORM_IS_DAP_ENABLED
      ? [
          {
            path: ROUTES.INERT_WASTE_APPLICATION,
            linkPath: ROUTES.INERT_WASTE_APPLICATION,
            icon: InertWasteApplicationNotificationIcon,
            applications: [UserApplication.FRONT_OFFICE],
            roles: [CLIENT_ADMIN_ROLE_CODE, CLIENT_CONTRIBUTOR_ROLE_CODE],
            analyticsProviderEventConfig: {
              action: ANALYTICS_PROVIDER_ACTIONS.CLIENT_REQUESTS_PAGE_VIEW,
              label: ANALYTICS_PROVIDER_LABELS.PAGE_VIEW,
              category: ANALYTICS_PROVIDER_CATEGORIES.CLIENT_REQUESTS,
            },
            pageComponent: InertWasteApplicationPage,
            lateralMenuLinkConfig: {
              title: lateralMenuIntl('see-inert-waste-application-for-prior-acceptance'),
            },
            homePageMenuSectionConfig: {
              title: homePageIntl(
                'menu-sections.see-inert-waste-application-for-prior-acceptance.title',
              ),
              description: homePageIntl(
                'menu-sections.see-inert-waste-application-for-prior-acceptance.description',
              ),
              actionButtonLabel: homePageIntl(
                'menu-sections.see-inert-waste-application-for-prior-acceptance.action-button-label',
              ),
            },
          },
        ]
      : []) as MenuPageConfig[]),
    {
      path: ROUTES.BUSINESS_ADMIN_CLIENT_REQUESTS,
      linkPath: ROUTES.BUSINESS_ADMIN_CLIENT_REQUESTS,
      icon: ClientRequestNotificationIcon,
      applications: [UserApplication.BACK_OFFICE],
      analyticsProviderEventConfig: {
        action: ANALYTICS_PROVIDER_ACTIONS.BUSINESS_ADMIN_CLIENT_REQUESTS_PAGE_VIEW,
        label: ANALYTICS_PROVIDER_LABELS.PAGE_VIEW,
        category: ANALYTICS_PROVIDER_CATEGORIES.CLIENT_REQUESTS,
      },
      pageComponent: BusinessAdminClientRequestsPage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('business-admin-client-requests'),
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.business-admin-client-requests.title'),
        description: homePageIntl('menu-sections.business-admin-client-requests.description'),
        actionButtonLabel: homePageIntl(
          'menu-sections.business-admin-client-requests.action-button-label',
        ),
      },
    },
    ...((PLATFORM_IS_DAP_ENABLED
      ? [
          {
            path: ROUTES.BUSINESS_ADMIN_INERT_WASTE_APPLICATIONS,
            linkPath: ROUTES.BUSINESS_ADMIN_INERT_WASTE_APPLICATIONS,
            icon: InertWasteApplicationNotificationIcon,
            applications: [UserApplication.BACK_OFFICE],
            analyticsProviderEventConfig: {
              action:
                ANALYTICS_PROVIDER_ACTIONS.BUSINESS_ADMIN_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_PAGE_VIEW,
              label: ANALYTICS_PROVIDER_LABELS.PAGE_VIEW,
              category:
                ANALYTICS_PROVIDER_CATEGORIES.BUSINESS_ADMIN_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE,
            },
            pageComponent: BusinessAdminInertWasteApplicationsPage,
            lateralMenuLinkConfig: {
              title: lateralMenuIntl('inert-waste-application-for-prior-acceptance'),
            },
            homePageMenuSectionConfig: {
              title: homePageIntl(
                'menu-sections.inert-waste-application-for-prior-acceptance.title',
              ),
              description: homePageIntl(
                'menu-sections.inert-waste-application-for-prior-acceptance.description',
              ),
              actionButtonLabel: homePageIntl(
                'menu-sections.inert-waste-application-for-prior-acceptance.action-button-label',
              ),
            },
          },
        ]
      : []) as MenuPageConfig[]),
    {
      path: ROUTES.HISTORY,
      icon: HistoryIcon,
      applications: [UserApplication.FRONT_OFFICE],
      analyticsProviderEventConfig: {
        action: ANALYTICS_PROVIDER_ACTIONS.DELIVERY_NOTES_PAGE_VIEW,
        label: ANALYTICS_PROVIDER_LABELS.PAGE_VIEW,
        category: ANALYTICS_PROVIDER_CATEGORIES.DELIVERY_NOTES,
      },
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('history'),
      },
      pageComponent: HistoryPage,
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.history.title'),
        description: homePageIntl('menu-sections.history.description'),
        actionButtonLabel: homePageIntl('menu-sections.history.action-button-label'),
      },
    },
    {
      path: ROUTES.CLIENT_COMPANIES,
      icon: ClientIcon,
      applications: [UserApplication.BACK_OFFICE],
      analyticsProviderEventConfig: {
        action: ANALYTICS_PROVIDER_ACTIONS.CLIENT_COMPANIES_PAGE_VIEW,
        label: ANALYTICS_PROVIDER_LABELS.PAGE_VIEW,
        category: ANALYTICS_PROVIDER_CATEGORIES.CLIENT_COMPANIES,
      },
      pageComponent: ClientCompaniesPage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('client-companies'),
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.client-companies.title'),
        description: homePageIntl('menu-sections.client-companies.description'),
        actionButtonLabel: homePageIntl('menu-sections.client-companies.action-button-label'),
      },
    },
    {
      path: ROUTES.SITES_AND_DOCUMENTS,
      icon: MapMarkerIcon,
      applications: [UserApplication.FRONT_OFFICE, UserApplication.BACK_OFFICE],
      roles: [
        CLIENT_ADMIN_ROLE_CODE,
        CLIENT_CONTRIBUTOR_ROLE_CODE,
        BUSINESS_ADMIN_ROLE_CODE,
        TECHNICAL_SUPER_ADMIN_ROLE_CODE,
      ],
      analyticsProviderEventConfig: {
        action: ANALYTICS_PROVIDER_ACTIONS.SITE_LOCATIONS_PAGE_VIEW,
        label: ANALYTICS_PROVIDER_LABELS.PAGE_VIEW,
        category: ANALYTICS_PROVIDER_CATEGORIES.SITE_LOCATIONS,
      },
      pageComponent: SitesAndDocumentsPage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('sites-and-documents'),
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.sites-and-documents.title'),
        description: homePageIntl('menu-sections.sites-and-documents.description', {
          legalCorporationName: PLATFORM_LEGAL_CORPORATION_LABEL.toUpperCase(),
        }),
        actionButtonLabel: homePageIntl('menu-sections.sites-and-documents.action-button-label'),
      },
    },
    {
      path: ROUTES.USERS,
      icon: AddUserIcon,
      applications: [UserApplication.FRONT_OFFICE],
      roles: [CLIENT_ADMIN_ROLE_CODE],
      isAllowedForInternalCompany: false,
      pageComponent: UsersPage,
      analyticsProviderEventConfig: {
        action: ANALYTICS_PROVIDER_ACTIONS.CLIENT_COMPANY_USERS_PAGE_VIEW,
        label: ANALYTICS_PROVIDER_LABELS.PAGE_VIEW,
        category: ANALYTICS_PROVIDER_CATEGORIES.CLIENT_COMPANY_USERS,
      },
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('manage-users'),
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.manage-users.title'),
        description: homePageIntl('menu-sections.manage-users.description'),
        actionButtonLabel: homePageIntl('menu-sections.manage-users.action-button-label'),
      },
    },
    {
      path: ROUTES.REFERENTS_MANAGEMENT,
      linkPath: ROUTES.REFERENTS_MANAGEMENT,
      icon: UserIcon,
      applications: [UserApplication.BACK_OFFICE],
      roles: [TECHNICAL_SUPER_ADMIN_ROLE_CODE],
      analyticsProviderEventConfig: {
        action: ANALYTICS_PROVIDER_ACTIONS.REFERENTS_MANAGEMENT_PAGE_VIEW,
        label: ANALYTICS_PROVIDER_LABELS.PAGE_VIEW,
        category: ANALYTICS_PROVIDER_CATEGORIES.REFERENTS_MANAGEMENT,
      },
      pageComponent: ReferentsManagementPage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('referents-management'),
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.referents-management.title'),
        description: homePageIntl('menu-sections.referents-management.description'),
        actionButtonLabel: homePageIntl('menu-sections.referents-management.action-button-label'),
      },
    },
  ];
};
