/* eslint-disable max-lines */
import {
  ApiResponseStatus,
  ApiResponseStatusSectionDisplay,
} from 'components/ApiResponseStatusSectionDisplay/ApiResponseStatusSectionDisplay';
import { Layout } from 'components/Layout/Layout';
import { useMenuPageConfigs } from 'hooks/useMenuPageConfigs/useMenuPageConfigs';
import { useTarteAuCitron } from 'hooks/useTarteAuCitron';
import {
  BusinessReferentsPage,
  ClientCompanyBusinessReferentsPage,
  ClientCompanyHistoryPage,
  ClientCompanyMainPage,
  ClientCompanyUsersPage,
  ElectronicSignatureEndPage,
  EvaluatorElectronicSignatureEnd,
  ExternalEvaluationPage,
  ExternalUserEvaluationSuccessAsyncPage,
  ExternalUserSynchronizationFailurePage,
  InertWasteApplicationGeneratorPage,
  InertWasteApplicationGeneratorSuccessPage,
  InvitationProcessEndPage,
  NotFoundPage,
  ProducerFormPage,
  SubscriptionRequestPage,
  SubscriptionRequestSuccessPage,
} from 'pages/lazyLoadedPages';
import { FunctionComponent, Suspense, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { useLocation } from 'react-use';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getSelectedPermission, UserApplication } from 'redux/User/selectors';
import { storePermissions } from 'redux/User/slice';
import { LazyRoute as Route } from 'routing/LazyRoute';
import { ProtectedRoute } from 'routing/ProtectedRoute';
import { ROUTES, UNAUTHENTICATED_ROUTES } from 'routing/routes';
import { analytics } from 'services/analytics/analytics';
import { fetchUserPermissions } from 'services/api/permissions/client';
import { CLIENT_ADMIN_ROLE_CODE, CLIENT_CONTRIBUTOR_ROLE_CODE } from 'services/api/roles/types';
import { useAuthenticationStatus } from 'services/authentication/useAuthenticationStatus';
import { logger } from 'services/logs/logger';

export const App: FunctionComponent = () => {
  useTarteAuCitron();
  const intl = useIntl();
  const apiResponseStatusSectionDisplayConfiguration = {
    errorStatusTitle: intl.formatMessage({
      id: 'app.error-response.title',
    }),
    errorStatusDescription: intl.formatMessage({
      id: 'app.error-response.description',
    }),
  };
  const [apiResponseStatus, setApiResponseStatus] = useState(ApiResponseStatus.LOADING);
  const authenticationStatus = useAuthenticationStatus();
  const dispatch = useAppDispatch();
  const location = useLocation();
  let shouldAuthenticateUser = true;
  if (location.pathname && UNAUTHENTICATED_ROUTES.includes(location.pathname)) {
    shouldAuthenticateUser = false;
  }
  const fetchAndStoreUserPermissions = useCallback(async () => {
    try {
      const permissions = await fetchUserPermissions();
      dispatch(storePermissions(permissions));
      setApiResponseStatus(ApiResponseStatus.SUCCESS);
    } catch (error) {
      logger.logError('Error fetching user permissions', error);
      setApiResponseStatus(ApiResponseStatus.ERROR);
    }
  }, [dispatch]);

  useEffect(() => {
    if (shouldAuthenticateUser) {
      if (authenticationStatus.isLoading) {
        setApiResponseStatus(ApiResponseStatus.LOADING);
      } else {
        if (authenticationStatus.isAuthenticated) {
          fetchAndStoreUserPermissions();
        } else {
          setApiResponseStatus(ApiResponseStatus.SUCCESS);
        }
      }
    } else {
      setApiResponseStatus(ApiResponseStatus.SUCCESS);
    }
  }, [
    fetchAndStoreUserPermissions,
    authenticationStatus.isLoading,
    authenticationStatus.isAuthenticated,
    shouldAuthenticateUser,
  ]);
  const menuPageConfigs = useMenuPageConfigs();

  const selectedPermission = useAppSelector(getSelectedPermission);

  useEffect(() => {
    if (selectedPermission) {
      analytics.setUserConfig(selectedPermission.userUniqueHash);
    }
  }, [selectedPermission]);

  return (
    <ApiResponseStatusSectionDisplay
      configuration={apiResponseStatusSectionDisplayConfiguration}
      status={apiResponseStatus}
    >
      <BrowserRouter>
        <Suspense fallback={<ApiResponseStatusSectionDisplay status={ApiResponseStatus.LOADING} />}>
          <Switch>
            <Route
              exact
              path={ROUTES.ELECTRONIC_SIGNATURE_END}
              component={ElectronicSignatureEndPage}
            />
            <Route
              exact
              path={ROUTES.INERT_WASTE_APPLICATION_GENERATOR}
              component={InertWasteApplicationGeneratorPage}
            />
            <Route
              exact
              path={ROUTES.INERT_WASTE_APPLICATION_GENERATOR_SUCCESS}
              component={InertWasteApplicationGeneratorSuccessPage}
            />
            <Route exact path={ROUTES.B2C_SILENT_REDIRECT} render={() => null} />
            <Route exact path={ROUTES.SUBSCRIPTION_REQUEST} component={SubscriptionRequestPage} />
            <Route
              exact
              path={ROUTES.SUBSCRIPTION_REQUEST_SUCCESS}
              component={SubscriptionRequestSuccessPage}
            />
            <Route
              exact
              path={ROUTES.INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION}
              component={ExternalEvaluationPage}
            />
            <Route
              exact
              path={ROUTES.INERT_WASTE_APPLICATION_SIGNATURE_ASYNC_SUCCESS}
              component={ExternalUserEvaluationSuccessAsyncPage}
            />
            <Route
              exact
              path={ROUTES.INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION_SIGNATURE_END}
              component={EvaluatorElectronicSignatureEnd}
            />
            <Route
              exact
              path={ROUTES.INERT_WASTE_APPLICATION_EVALUATION_ASYNC_SUCCESS}
              component={ExternalUserEvaluationSuccessAsyncPage}
            />
            <Route
              exact
              path={ROUTES.INERT_WASTE_APPLICATION_EXTERNAL_SYNCHRONIZATION_FAILURE}
              component={ExternalUserSynchronizationFailurePage}
            />
            <Layout>
              <Suspense
                fallback={<ApiResponseStatusSectionDisplay status={ApiResponseStatus.LOADING} />}
              >
                <Switch>
                  <Redirect exact from="/" to={ROUTES.HOME} />
                  {menuPageConfigs.map((menuPageConfig) => {
                    return (
                      <ProtectedRoute
                        exact
                        key={menuPageConfig.linkPath ?? menuPageConfig.path}
                        path={menuPageConfig.path}
                        component={menuPageConfig.pageComponent}
                        applications={menuPageConfig.applications}
                        roles={menuPageConfig.roles}
                        isAllowedForInternalCompany={menuPageConfig.isAllowedForInternalCompany}
                      />
                    );
                  })}
                  <Route
                    exact
                    path={ROUTES.INERT_WASTE_APPLICATION_SIGNATURE_FROM_WASTE_PRODUCER}
                    component={ProducerFormPage}
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.CLIENT_COMPANY}
                    component={ClientCompanyMainPage}
                    applications={[UserApplication.BACK_OFFICE]}
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.CLIENT_COMPANY_USERS}
                    component={ClientCompanyUsersPage}
                    applications={[UserApplication.BACK_OFFICE]}
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.CLIENT_COMPANY_HISTORY}
                    component={ClientCompanyHistoryPage}
                    applications={[UserApplication.BACK_OFFICE]}
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.CLIENT_COMPANY_BUSINESS_REFERENTS}
                    component={ClientCompanyBusinessReferentsPage}
                    applications={[UserApplication.BACK_OFFICE]}
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.BUSINESS_REFERENTS}
                    component={BusinessReferentsPage}
                    applications={[UserApplication.FRONT_OFFICE]}
                    roles={[CLIENT_ADMIN_ROLE_CODE, CLIENT_CONTRIBUTOR_ROLE_CODE]}
                  />
                  <Route
                    exact
                    path={ROUTES.INVITATION_PROCESS_END}
                    component={InvitationProcessEndPage}
                  />
                  <Route exact path={ROUTES.NOT_FOUND} component={NotFoundPage} />
                  <Route exact path={ROUTES.HEALTH_CHECK_FRONTEND} render={() => null} />
                  <Route component={NotFoundPage} />
                </Switch>
              </Suspense>
            </Layout>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ApiResponseStatusSectionDisplay>
  );
};
