/* eslint-disable max-lines */
import { makeStyles } from '@material-ui/core';
import { colorPalette, fontFamily, fontSize, getSizeInPx, getSpacing } from 'stylesheet';

import { AdditionalButtonProps } from './Button.types';

export const useButtonStyle = ({
  margins,
  bigPaddings,
  paddings,
  displayStyle,
  isLoading,
  children,
  justifyContentLeft,
  widthInPx,
  customStyle,
}: AdditionalButtonProps) =>
  makeStyles({
    root: {
      fontFamily: fontFamily.main,
      fontSize: fontSize.p1,
      fontWeight: () => {
        switch (displayStyle) {
          case 'filledOrange':
          case 'filledRed':
          case 'filledBlue':
          case 'unfilledGrey':
          case 'unfilledGreyWithoutBorder':
          case 'unfilledOrange':
          case 'unfilledRed':
          case 'unfilledBlue':
          case 'underlineText':
          case 'primary':
            return 'normal';
          case 'text':
            return 'bold';
        }
      },
      height: getSpacing(5),
      minWidth: getSpacing(3),
      ...(widthInPx && { width: `${getSizeInPx(widthInPx)}` }),
      textTransform: 'inherit',
      letterSpacing: 'inherit',
      margin: margins ?? '0',
      padding:
        paddings ??
        (bigPaddings ? `${getSpacing(1)} ${getSpacing(5)}` : `${getSpacing(1)} ${getSpacing(2)}`),
      borderRadius: getSpacing(1),
      '& .MuiButton-label': {
        justifyContent: justifyContentLeft ? 'flex-start' : 'inherit',
        ...(isLoading && {
          position: 'relative',
          visibility: 'hidden',
          '& .MuiCircularProgress-root': {
            visibility: 'visible',
          },
        }),
      },
      ...(isLoading && {
        pointerEvents: 'none',
      }),

      color: () => {
        switch (displayStyle) {
          case 'filledOrange':
          case 'filledRed':
          case 'filledBlue':
          case 'primary':
            return colorPalette.white;
          case 'unfilledGrey':
          case 'unfilledGreyWithoutBorder':
          case 'unfilledOrange':
          case 'unfilledRed':
          case 'unfilledBlue':
            return colorPalette.greyMain;
          case 'underlineText':
          case 'text':
            return colorPalette.secondary_600;
        }
      },
      border: () => {
        switch (displayStyle) {
          case 'filledOrange':
          case 'unfilledOrange':
            return `solid 1px ${colorPalette.secondary_600}`;
          case 'unfilledGrey':
            return `solid 1px ${colorPalette.greyMain}`;
          case 'filledRed':
          case 'unfilledRed':
            return `solid 1px ${colorPalette.status_error}`;
          case 'filledBlue':
          case 'unfilledBlue':
            return `solid 1px ${colorPalette.blue_500}`;
          case 'primary':
            return `solid 1px ${colorPalette.primary}`;
          case 'unfilledGreyWithoutBorder':
          case 'underlineText':
          case 'text':
            return 'unset';
        }
      },
      backgroundColor: () => {
        switch (displayStyle) {
          case 'filledOrange':
            return colorPalette.secondary_600;
          case 'filledRed':
            return colorPalette.status_error;
          case 'filledBlue':
            return colorPalette.blue_500;
          case 'unfilledGrey':
          case 'unfilledOrange':
          case 'unfilledRed':
          case 'unfilledBlue':
            return colorPalette.white;
          case 'primary':
            return colorPalette.primary;
          case 'unfilledGreyWithoutBorder':
          case 'underlineText':
          case 'text':
            return 'unset';
        }
      },
      '&:hover,&:focus-visible,&:hover .MuiButton-startIcon,&:focus-visible .MuiButton-startIcon': {
        color: displayStyle === 'text' ? colorPalette.secondary_600 : colorPalette.white,
        textDecoration: displayStyle === 'underlineText' ? 'underline' : 'none',
      },
      '&:hover,&:focus-visible': {
        border: () => {
          switch (displayStyle) {
            case 'filledOrange':
              return `solid 1px ${colorPalette.secondary_300}`;
            case 'primary':
              return `solid 1px ${colorPalette.primary}`;
            case 'filledRed':
              return `solid 1px ${colorPalette.status_error_light}`;
            case 'unfilledGrey':
              return `solid 1px ${colorPalette.blue_400}`;
            case 'unfilledOrange':
              return `solid 1px ${colorPalette.secondary_600}`;
            case 'filledBlue':
              return `solid 1px ${colorPalette.blue_500}`;
            case 'unfilledGreyWithoutBorder':
              return 'none';
            case 'unfilledRed':
              return `solid 1px ${colorPalette.status_error}`;
            case 'unfilledBlue':
              return `solid 1px ${colorPalette.blue_500}`;
          }
        },
        backgroundColor: () => {
          switch (displayStyle) {
            case 'filledOrange':
              return colorPalette.secondary_300;
            case 'primary':
              return colorPalette.white;
            case 'filledRed':
              return colorPalette.status_error_light;
            case 'unfilledGrey':
              return colorPalette.blue_400;
            case 'unfilledOrange':
              return colorPalette.secondary_600;
            case 'filledBlue':
              return colorPalette.blue_500;
            case 'unfilledGreyWithoutBorder':
              return 'unset';
            case 'unfilledRed':
              return colorPalette.status_error;
            case 'unfilledBlue':
              return colorPalette.blue_500;
            case 'text':
              return colorPalette.secondary_100;
            case 'underlineText':
              return 'transparent';
          }
        },
        color: () => {
          switch (displayStyle) {
            case 'primary':
              return colorPalette.primary;
            case 'unfilledGreyWithoutBorder':
              return colorPalette.grey_400;
            case 'underlineText':
            case 'text':
              return colorPalette.secondary_600;
          }
        },
      },
      '&:disabled': {
        border: () => {
          switch (displayStyle) {
            case 'filledOrange':
            case 'filledRed':
            case 'filledBlue':
              return `solid 1px ${colorPalette.grey_400}`;
            case 'unfilledGrey':
            case 'unfilledOrange':
            case 'unfilledRed':
            case 'unfilledBlue':
              return `solid 1px ${colorPalette.grey_600}`;
            case 'unfilledGreyWithoutBorder':
            case 'text':
              return 'none';
          }
        },
        backgroundColor: () => {
          switch (displayStyle) {
            case 'filledOrange':
            case 'filledRed':
            case 'filledBlue':
              return colorPalette.grey_400;
            case 'unfilledGrey':
            case 'unfilledOrange':
            case 'unfilledRed':
            case 'unfilledBlue':
              return colorPalette.white;
            case 'unfilledGreyWithoutBorder':
            case 'text':
              return 'unset';
          }
        },
      },
      '&:disabled,&:disabled .MuiButton-startIcon': {
        color: colorPalette.grey_600,
      },
      ...customStyle,
    },
    startIcon: {
      color: () => {
        switch (displayStyle) {
          case 'filledOrange':
          case 'filledRed':
          case 'filledBlue':
          case 'primary':
            return colorPalette.white;
          case 'unfilledGrey':
          case 'unfilledGreyWithoutBorder':
            return colorPalette.greyMain;
          case 'unfilledOrange':
            return colorPalette.secondary_600;
          case 'unfilledRed':
            return colorPalette.status_error;
          case 'unfilledBlue':
            return colorPalette.blue_500;
          case 'text':
            return colorPalette.secondary_600;
        }
      },
      marginLeft: 0,
      marginRight: children ? getSpacing(2) : 0,
    },
  });
