/* eslint-disable max-lines */
import { RootState } from 'redux/store';
import { isFilterEmpty } from 'services/utils/filtersToQueryParams';

import { FilterKey, TableFilters, TableSort } from './filtersKeys';
import { TableKey } from './tableKey';
import { TableState } from './types';

export const getTable = (tableKey: TableKey) => {
  switch (tableKey) {
    case TableKey.DELIVERY_NOTES:
      return getDeliveryNotesTable;
    case TableKey.INVOICES:
      return getInvoicesTable;
    case TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS:
      return getBusinessAdminClientRequestsTable;
    case TableKey.CLIENT_REQUESTS:
      return getClientRequestsTable;
    case TableKey.CLIENT_COMPANIES:
      return getClientCompaniesTable;
    case TableKey.USER_CLIENT_COMPANIES:
      return getUserClientCompaniesTable;
    case TableKey.CLIENT_COMPANY_BUSINESS_REFERENTS:
      return getClientCompanyBusinessReferentsTable;
    case TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE:
      return getInertWasteApplicationForPriorAcceptanceTable;
    case TableKey.BUSINESS_REFERENTS_MANAGEMENT:
      return getBusinessReferentsManagementTable;
    case TableKey.INERT_WASTE_APPLICATION_REFERENTS:
      return getInertWasteApplicationReferentsTable;
    default:
      return () => ({
        selectAll: false,
        selectedIds: [],
        isReloading: false,
        filters: {} as Record<FilterKey, string>,
        sort: null,
      });
  }
};
export const getDeliveryNotesTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.DELIVERY_NOTES];
const getInvoicesTable = (rootState: RootState): TableState => rootState.tables[TableKey.INVOICES];
export const getClientCompaniesTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.CLIENT_COMPANIES];
const getUserClientCompaniesTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.USER_CLIENT_COMPANIES];
const getBusinessAdminClientRequestsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS];
const getClientRequestsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.CLIENT_REQUESTS];
export const getSiteDocumentsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.SITE_DOCUMENTS];
const getClientCompanyUsersTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.CLIENT_COMPANY_USERS];
const getClientCompanyBusinessReferentsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.CLIENT_COMPANY_BUSINESS_REFERENTS];
export const getInertWasteApplicationForPriorAcceptanceTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE];
const getBusinessReferentsManagementTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.BUSINESS_REFERENTS_MANAGEMENT];
const getInertWasteApplicationReferentsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.INERT_WASTE_APPLICATION_REFERENTS];

export const isTableSelected = (tableKey: TableKey) => {
  switch (tableKey) {
    case TableKey.DELIVERY_NOTES:
      return isDeliveryNotesTableSelected;
    case TableKey.INVOICES:
      return isInvoicesTableSelected;
    default:
      return () => false;
  }
};
export const isDeliveryNotesTableSelected = (rootState: RootState): boolean =>
  getDeliveryNotesTable(rootState).selectAll;

const isInvoicesTableSelected = (rootState: RootState): boolean =>
  getInvoicesTable(rootState).selectAll;

export const isTableReloading = (tableKey: TableKey) => {
  switch (tableKey) {
    case TableKey.DELIVERY_NOTES:
      return isDeliveryNotesTableReloading;
    case TableKey.CLIENT_COMPANIES:
      return isClientCompaniesTableReloading;
    case TableKey.USER_CLIENT_COMPANIES:
      return isUserClientCompaniesTableReloading;
    case TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS:
      return isBusinessAdminClientRequestsTableReloading;
    case TableKey.CLIENT_REQUESTS:
      return isClientRequestsTableReloading;
    case TableKey.SITE_DOCUMENTS:
      return isSiteDocumentsTableReloading;
    case TableKey.CLIENT_COMPANY_USERS:
      return isClientCompanyUsersTableReloading;
    case TableKey.INVOICES:
      return isInvoicesTableReloading;
    case TableKey.CLIENT_COMPANY_BUSINESS_REFERENTS:
      return isClientCompanyBusinessReferentsTableReloading;
    case TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE:
      return isInertWasteApplicationForPriorAcceptanceTableReloading;
    case TableKey.BUSINESS_REFERENTS_MANAGEMENT:
      return isBusinessReferentsManagementTableReloading;
    case TableKey.INERT_WASTE_APPLICATION_REFERENTS:
      return isInertWasteApplicationReferentsTableReloading;
    default:
      return () => false;
  }
};
export const isDeliveryNotesTableReloading = (rootState: RootState): boolean =>
  getDeliveryNotesTable(rootState).isReloading;
const isClientCompaniesTableReloading = (rootState: RootState): boolean =>
  getClientCompaniesTable(rootState).isReloading;
const isUserClientCompaniesTableReloading = (rootState: RootState): boolean =>
  getUserClientCompaniesTable(rootState).isReloading;
const isBusinessAdminClientRequestsTableReloading = (rootState: RootState): boolean =>
  getBusinessAdminClientRequestsTable(rootState).isReloading;
const isClientRequestsTableReloading = (rootState: RootState): boolean =>
  getClientRequestsTable(rootState).isReloading;
const isSiteDocumentsTableReloading = (rootState: RootState): boolean =>
  getSiteDocumentsTable(rootState).isReloading;
const isClientCompanyUsersTableReloading = (rootState: RootState): boolean =>
  getClientCompanyUsersTable(rootState).isReloading;
const isInvoicesTableReloading = (rootState: RootState): boolean =>
  getInvoicesTable(rootState).isReloading;
const isClientCompanyBusinessReferentsTableReloading = (rootState: RootState): boolean =>
  getClientCompanyBusinessReferentsTable(rootState).isReloading;
const isInertWasteApplicationForPriorAcceptanceTableReloading = (rootState: RootState): boolean =>
  getInertWasteApplicationForPriorAcceptanceTable(rootState).isReloading;
const isBusinessReferentsManagementTableReloading = (rootState: RootState): boolean =>
  getBusinessReferentsManagementTable(rootState).isReloading;
const isInertWasteApplicationReferentsTableReloading = (rootState: RootState): boolean =>
  getInertWasteApplicationReferentsTable(rootState).isReloading;

const EMPTY_OBJECT_HOLDING_CONSTANT_REFERENCE = {};
export const getTableFilters = (tableKey: TableKey) => {
  switch (tableKey) {
    case TableKey.CLIENT_COMPANIES:
      return getClientCompaniesTableFilters;
    case TableKey.USER_CLIENT_COMPANIES:
      return getUserClientCompaniesTableFilters;
    case TableKey.CLIENT_REQUESTS:
      return getClientRequestsTableFilters;
    case TableKey.SITE_DOCUMENTS:
      return getSiteDocumentsTableFilters;
    case TableKey.DELIVERY_NOTES:
      return getDeliveryNotesTableFilters;
    case TableKey.SPECIFIC_SITE_DOCUMENTS:
      return getSpecificSiteDocumentsTableFilters;
    case TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS:
      return getBusinessAdminClientRequestsTableFilters;
    case TableKey.INVOICES:
      return getInvoicesTableFilters;
    case TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE:
      return getInertWasteApplicationForPriorAcceptanceTableFilters;
    case TableKey.BUSINESS_REFERENTS_MANAGEMENT:
      return getBusinessReferentsManagementTableFilters;
    case TableKey.INERT_WASTE_APPLICATION_REFERENTS:
      return getInertWasteApplicationReferentsTableFilters;
    default:
      return () => EMPTY_OBJECT_HOLDING_CONSTANT_REFERENCE as TableFilters;
  }
};

export const getClientCompaniesTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.CLIENT_COMPANIES].filters;

const getUserClientCompaniesTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.USER_CLIENT_COMPANIES].filters;

const getBusinessAdminClientRequestsTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS].filters;

const getClientRequestsTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.CLIENT_REQUESTS].filters;

export const getSiteDocumentsTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.SITE_DOCUMENTS].filters;

const getDeliveryNotesTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.DELIVERY_NOTES].filters;

const getInvoicesTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.INVOICES].filters;

const getSpecificSiteDocumentsTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.SPECIFIC_SITE_DOCUMENTS].filters;

const getInertWasteApplicationForPriorAcceptanceTableFilters = (
  rootState: RootState,
): TableFilters => rootState.tables[TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE].filters;

const getBusinessReferentsManagementTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.BUSINESS_REFERENTS_MANAGEMENT].filters;

const getInertWasteApplicationReferentsTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.INERT_WASTE_APPLICATION_REFERENTS].filters;

export const getTableFiltersWithoutSearch = (filters: TableFilters) => {
  const { [FilterKey.SEARCH]: searchFilterValue, ...filtersValuesExceptSearch } = filters;

  return filtersValuesExceptSearch;
};

export const getNumberOfAppliedFilters = (filters: TableFilters) => {
  const filterValues = Object.values(filters);

  return filterValues.filter(isFilterEmpty).length;
};

export const getTableSort = (tableKey: TableKey) => {
  switch (tableKey) {
    case TableKey.DELIVERY_NOTES:
      return getDeliveryNotesTableSort;
    case TableKey.INVOICES:
      return getInvoicesTableSort;
    case TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS:
      return getBusinessAdminClientRequestsTableSort;
    case TableKey.CLIENT_REQUESTS:
      return getClientRequestsTableSort;
    case TableKey.CLIENT_COMPANIES:
      return getClientCompaniesTableSort;
    case TableKey.USER_CLIENT_COMPANIES:
      return getUserClientCompaniesTableSort;
    case TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE:
      return getInertWasteApplicationForPriorAcceptanceTableSort;
    case TableKey.BUSINESS_REFERENTS_MANAGEMENT:
      return getBusinessReferentsManagementTableSort;
    case TableKey.INERT_WASTE_APPLICATION_REFERENTS:
      return getInertWasteApplicationReferentsTableSort;
    default:
      return () => null;
  }
};

const getDeliveryNotesTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.DELIVERY_NOTES].sort;

const getInvoicesTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.INVOICES].sort;

const getClientRequestsTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.CLIENT_REQUESTS].sort;

const getBusinessAdminClientRequestsTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS].sort;

const getClientCompaniesTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.CLIENT_COMPANIES].sort;

const getUserClientCompaniesTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.USER_CLIENT_COMPANIES].sort;

const getInertWasteApplicationForPriorAcceptanceTableSort = (
  rootState: RootState,
): TableSort | null => rootState.tables[TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE].sort;

const getBusinessReferentsManagementTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.BUSINESS_REFERENTS_MANAGEMENT].sort;

const getInertWasteApplicationReferentsTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.INERT_WASTE_APPLICATION_REFERENTS].sort;
